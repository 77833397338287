<template>
    <div class="messageCenter">
        <header>
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="消息类型：">
                    <el-select v-model="query.messageType" class="search-input" placeholder="请选择">
                        <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </el-form-item>

                <el-form-item label="当前办理状态：">
                    <el-select v-model="query.status" class="search-input" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="发送人：">
                    <el-input class="search-input" clearable v-model="query.sendPersonName" placeholder="请输入" />
                </el-form-item>
            </el-form>
            <div class="btns">
                <el-button @click="search" type="primary">查 询</el-button>
                <el-button @click="resize" plain>重 置</el-button>
            </div>
        </header>
        <main>
            <div class="subToolbar">
                <div class="title">消息列表</div>
            </div>
            <div class="table">
                <el-table
                    :data="tableData"
                    style="width: 100%; height: 100%"
                    header-row-class-name="table-header"
                    height="calc(100% - 40px)"
                    :header-cell-style="{
                        background: '#F7F8FA',
                        color: '#333333',
                        height: '54px',
                        'font-size': '14px',
                    }"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                >
                    <el-table-column type="index" width="70" label="序号" />
                    <el-table-column prop="messageType" label="消息类型" width="180">
                        <template #default="{ row }">
                            <div class="rows">
                                <span :class="['type', 'type' + row.messageType]" v-if="row.messageType == '1'" slot="reference">审核提醒</span>
                                <span :class="['type', 'type' + row.messageType]" v-if="row.messageType == '2'" slot="reference">驳回提醒</span>
                                <span :class="['type', 'type' + row.messageType]" v-if="row.messageType == '3'" slot="reference">执行通知</span>
                                <span :class="['type', 'type' + row.messageType]" v-if="row.messageType == '4'" slot="reference">执行预警</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="messageContent" label="消息内容" min-width="220"></el-table-column>
                    <el-table-column prop="sendPersonName" label="发送人"></el-table-column>
                    <el-table-column prop="sendTime" sortable label="发送时间" width=""> </el-table-column>
                    <el-table-column prop="status" label="当前办理状态" width="">
                        <template #default="{ row }">
                            <div>
                                <span :class="['status', 'status' + row.status]" v-if="row.status == '0'" slot="reference">• 未办理</span>
                                <span :class="['status', 'status' + row.status]" v-else slot="reference">• 已办理</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <div>
                        <el-pagination
                            v-model:currentPage="query.page"
                            v-model:page-size="query.size"
                            @current-change="getList"
                            @size-change="getList"
                            :page-sizes="[15, 30, 50, 100]"
                            background
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import axios from 'axios';
import $store from '@/store';
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useStore } from 'vuex';

const { proxy } = getCurrentInstance();
let store = useStore();
const roles = store.state.user.roles;
function isDisables(role) {
    let res = true;
    role.forEach(e => {
        if (roles.indexOf(e) > -1) {
            res = false;
        }
        return res;
    });
    return res;
}
const router = useRouter();
const emit = defineEmits(['close']);
const props = defineProps({});
let query = ref({
    page: 1,
    size: 15,
    status: '',
    messageType: '',
    sendPersonName: '',
});
let checkData = ref([]);
let total = ref(0);
const options = ref([
    {
        label: '全部',
        value: '',
    },
    {
        label: '未办理',
        value: 0,
    },
    {
        label: '已办理',
        value: 1,
    },
]);
const systemOptions = ref([]);
const typeOptions = ref([
    {
        label: '全部',
        value: '',
    },
    {
        label: '审核提醒',
        value: 1,
    },
    {
        label: '驳回提醒',
        value: 2,
    },
    {
        label: '执行通知',
        value: 3,
    },
    {
        label: '执行预警',
        value: 4,
    },
]);
let tableData = ref([]);
function getOption() {
    axios({
        url: '/system/dict/data/list',
        method: 'get',
        params: {
            dictType: 'system_module',
        },
    }).then(res => {
        systemOptions.value = res;
    });
    axios({
        url: '/system/dict/data/list',
        method: 'get',
        params: {
            dictType: 'sys_oper_type',
        },
    }).then(ret => {
        statusOptions.value = ret;
    });
}
function getList() {
    proxy.$api.frontMessage.getPage(query.value).then(res => {
        if (res.code == 200) {
            tableData.value = res.data.records;
            total.value = res.data.total;
        }
    });
}
function search() {
    query.value.page = 1;
    getList();
}
function resize() {
    query.value = {
        page: 1,
        size: 15,
        status: '',
        messageType: '',
        sendPersonName: '',
    };
    getList();
}

const test = computed(() => {
    return null;
});
watch(() => {});
onMounted(() => {
    // getOption();
    getList();
});
onUnmounted(() => {});
</script>
<style lang="stylus" scoped>
.messageCenter {
    height: calc(100% - 32px);
    header {
        height: 64px;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        padding: 16px 24px 0 24px;
        box-sizing: border-box;
        .btns {
            white-space: nowrap;
        }
    }
    main {
        margin-top: 16px;
        height: calc(100% - 85px);
        padding: 16px 24px 58px 24px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        .subToolbar {
            text-align: left;
            margin-bottom: 16px;
        }
        .table {
            height: calc(100% - 46px);
            .table-header {
                height: calc(100% - 40px);
            }
            .pagination {
                text-align: right;
                margin-top: 10px;
            }
        }
        /deep/.el-table th.el-table__cell {
            background: #f6f8fa;
        }
        .rows {
            height: 54px;
            line-height: 54px;
        }
        .type {
            width: 76px;
            height: 28px;
            box-sizing: border-box;
            padding: 6px 10px;
            border-radius: 4px;
            &.type3 {
                background: rgba(100, 183, 255, 0.2);
                color: #1492ff;
                border: 1px solid #1492ff;
            }
            &.type1 {
                background: rgba(255, 24, 216, 0.1);
                color: #ff18d8;
                border: 1px solid #ff18d8;
            }
            &.type2 {
                background: rgba(255, 139, 0, 0.1);
                color: #ff8b00;
                border: 1px solid #ff8b00;
            }
            &.type4 {
                background: rgba(255, 24, 24, 0.1);
                color: #ff1818;
                border: 1px solid #ff1818;
            }
        }
        .status {
            display: inline-block;
            width: 68px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            &.status0 {
                background: rgba(68, 203, 87, 0.2);
                color: #44cd40;
            }
            &.status1 {
                background: rgba(216, 216, 216, 0.2);
                color: #999999;
            }
        }
    }
}
</style>
